<template>
    <div class="stage-inner-container-padded">
        <!-- Route Header -->
        <b-row >
            <b-col cols="10">
                <h1>{{name_pluaral}}</h1>
            </b-col>
            <b-col cols="2">
                    <i class="material-icons add-link float-right"
                        v-if="$store.getters.checkUIPC({path: ['utilities', 'actions', 'create'] })"
                        @click="createNewRow()"
                        @mouseover="createNewHoverStart"
                        @mouseleave="createNewHoverEnd"
                    >add_box</i>
            </b-col>
        </b-row>
        <!-- Table -->
        <b-table striped hover small dark :items="values" :fields="tableFields" :busy="tableBusy">
            <template #cell(controls)="data">
                <b-button-group size="sm">
                <b-button class='material-icons' variant="info" v-if="$store.getters.checkUIPC({path: ['utilities', 'actions', 'update'] })" @click="editRow(data.item)">edit</b-button>
                <!-- <b-button class='material-icons' variant="danger" @click="showConfirmationModal(confirmDeleteModal, data.item, data.item.id)">remove_circle_outline</b-button> -->
                </b-button-group>
            </template>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Fetching Table Data, Please Stand By</strong>
                </div>
            </template>
        </b-table>
        <!-- Edit/Create Modal -->
        <b-modal id="choice-editor" size="lg">
                <template slot="modal-title">
                    {{editModal.type}} {{name_singular}}
                </template>
                <b-form-group
                    :id=" 'fieldset-horizontal-' + name_singular.toLowerCase() + '-id' "
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    :label="transformToProperCase(pkid)"
                    :label-for="'input-horizontal-' + name_singular.toLowerCase() + '-id'"
                >
                    <b-form-input disabled 
                        :id="'input-horizontal-' + name_singular.toLowerCase() + '-id'" 
                        v-model="editModal.actee[pkid]">
                    </b-form-input>
                </b-form-group>
                <b-form-group 
                    id="fieldset-horizontal-utility_name"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    label="Utility Name"
                    label-for="input-horizontal-utility_name"
                >
                    <b-form-input id="input-horizontal-utility_name" v-model="editModal.actee.utility_name"></b-form-input>
                </b-form-group >
                <!-- ADD Site Type Drop Down Here -->
                <b-form-group 
                    id="fieldset-horizontal-first-name"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    label="Contact First Name"
                    label-for="input-horizontal-first-name"
                >
                    <b-form-input id="input-horizontal-first-name" v-model="editModal.actee.utility_contact_first_name"></b-form-input>
                    
                </b-form-group >
                <b-form-group
                    id="fieldset-horizontal-first-name"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    description=""
                    label="Contact Last Name"
                    label-for="input-horizontal-first-name"
                >
                    <b-form-input id="input-horizontal-first-name" v-model="editModal.actee.utility_contact_last_name"></b-form-input>
                </b-form-group>
                <!-- Phone Number(s) -->
                <b-form-group label-cols-sm="4" label-cols-lg="3" label-class="pt-0" class="mb-0" label="Contact Phone Number(s)">
                    <div class="array-spaced" v-for="(number, index) in editModal.actee.utility_contact_phone_number" :key="index">
                        <b-input-group id="fieldset-horizontal-phone" label-cols-sm="4" label-cols-lg="3" description="" label-for="input-horizontal-phone" >
                            <!-- Phone Numebrs DO NOT VALIDATE, VALIDATION IS A TODO ITEM -->
                            <b-form-input 
                                id="input-horizontal-phone" 
                                v-model="editModal.actee.utility_contact_phone_number[index]"
                            ></b-form-input>
                            <!-- Append The Delete Button To The Alias Input -->
                            <b-input-group-append>
                                <b-button 
                                class='material-icons modal-btn' 
                                variant="danger" 
                                @click="removeArrayItem('utility_contact_phone_number', index)"
                            >
                                remove_circle_outline
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                
                    <!-- Space For Adding New Phone Numbers -->
                    <b-input-group 
                        key="newPhone"
                        id="fieldset-horizontal-new-phone"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        description=""
                        label-for="input-horizontal-new-phone"
                        label="New Phone Number"
                        prepend="Add"
                    >
                        <b-form-input 
                            id="input-horizontal-new-phone" 
                            v-model="editModal.newPhone" 
                            @keyup.enter.native="addArrayItem('utility_contact_phone_number', 'newPhone')"
                        >
                        </b-form-input>
                        
                        <b-input-group-append>
                            <b-button 
                            class='material-icons modal-btn' 
                            variant="success" 
                            @click="addArrayItem('utility_contact_phone_number', 'newPhone')"
                            
                            >
                            add_circle_outline
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <div class="fgroup-spacer"></div>
                <!-- Emails -->
                <b-form-group label-cols-sm="4" label-cols-lg="3" label-class="pt-0" class="mb-0" label="Contact Email(s)">
                    <div class="array-spaced" v-for="(number, index) in editModal.actee.utility_contact_email" :key="index">
                        <b-input-group id="fieldset-horizontal-email" label-cols-sm="4" label-cols-lg="3" description="" label-for="input-horizontal-phone" >
                            <!-- Emails DO NOT VALIDATE, VALIDATION IS A TODO ITEM -->
                            <b-form-input 
                                id="input-horizontal-email" 
                                v-model="editModal.actee.utility_contact_email[index]"
                            ></b-form-input>
                            <!-- Append The Delete Button To The Alias Input -->
                            <b-input-group-append>
                                <b-button class='material-icons modal-btn' 
                                variant="danger" 
                                @click="removeArrayItem('utility_contact_email', index)"
                            >
                                remove_circle_outline
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                
                    <!-- Space For Adding New Emails -->
                    <b-input-group 
                        key="newEmail"
                        id="fieldset-horizontal-new-email"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        description=""
                        label-for="input-horizontal-new-email"
                        label="New Email"
                        prepend="Add"
                    >
                        <b-form-input 
                            id="input-horizontal-new-email" 
                            v-model="editModal.newEmail" 
                            @keyup.enter.native="addArrayItem('utility_contact_email', 'newEmail')"
                        >
                        </b-form-input>
                        
                        <b-input-group-append>
                            <b-button 
                            class='material-icons modal-btn' 
                            variant="success" 
                            @click="addArrayItem('utility_contact_email', 'newEmail')"
                            
                            >
                            add_circle_outline
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <div class="fgroup-spacer"></div>
                <!-- Address input -->
                <b-form-group
                    v-if="hasAddress"
                    id="address-label"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    :label="transformToProperCase(addressName)"
                    label-for="address-input"
                >
                <b-input-group 
                    key="address"
                    id="fieldset-horizontal-new-email"
                    description=""
                    label-for="input-horizontal-new-email"
                >
                    <b-form-input 
                        id="address-input" 
                        v-model="editModal.adrsInput"
                        placeholder="Start Typing An Address"
                        @input="runAdrsInput()"
                    >
                    </b-form-input>
                    
                    <b-input-group-append>
                        <b-button 
                            v-show="!editModal.showAdvancedAdrs"
                            class='material-icons modal-btn' 
                            variant="success" 
                            @click="toggleAdvancedAddress()"
                        >
                            arrow_drop_down
                        </b-button>
                        <b-button 
                            v-show="editModal.showAdvancedAdrs"
                            class='material-icons modal-btn' 
                            variant="success" 
                            @click="toggleAdvancedAddress()"
                        >
                            arrow_drop_up
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                   
                </b-form-group>
                <b-collapse id="address-advanced" v-model="editModal.showAdvancedAdrs" class="mt-2">
                    <b-row no-gutters>
                        <b-col cols="3">
                            <b-form-input v-model="editModal.parsedAddress.utility_address_number"></b-form-input>
                        </b-col>
                        <b-col cols="6">
                            <b-form-input v-model="editModal.parsedAddress.utility_address_street_name"></b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <b-form-input v-model="editModal.parsedAddress.utility_address_street_suffix"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row no-gutters>
                        <b-col cols="12">
                            <b-form-input v-model="editModal.parsedAddress.utility_address_postdirectional"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row no-gutters>
                        <b-col cols="7">
                            <b-form-input v-model="editModal.parsedAddress.utility_address_city"></b-form-input>
                        </b-col>
                        <b-col cols="2">
                            <b-form-input v-model="editModal.parsedAddress.utility_address_state"></b-form-input>
                        </b-col>
                        <b-col cols="2">
                            <b-form-input v-model="editModal.parsedAddress.utility_address_zip_code"></b-form-input>
                        </b-col>
                        <b-col cols="1">
                            <b-form-input v-model="editModal.parsedAddress.utility_address_zip_code_plus_four"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-button @click="fetchGeocode(editModal.adrsInput)">Validate Address</b-button>
                </b-collapse>
                <template slot="modal-footer">
                    <b-button block v-show="!editModal.processing" class="mt-3" variant="danger" @click="$bvModal.hide('choice-editor')">Discard</b-button>
                    <b-button block class="mt-3" variant="success" @click="editModalSave()">
                        <b-spinner v-show="editModal.processing" label="Loading..."></b-spinner>
                        <span v-show="!editModal.processing">Save</span>
                    </b-button>
                </template>
        </b-modal>
        <confirm-address v-bind:modalVisible="showAddressSelect" v-bind:onCloseOrHide="hideSecondModal"></confirm-address>
        <b-modal id="confirmation-window" size="lg">
            <template slot="modal-title">
                Are You Sure You Wish To Delete {{confirmationModal.title}}
            </template>
            <div v-show="confirmationModal.type == 'PERM'" >
                Deletion is Permanent And Cannot Be Undone, Are You Sure You Didn't Mean Disable?
            </div>
            <template slot="modal-footer">
                <b-button block class="mt-3" variant="success" @click="$bvModal.hide('confirmation-window')">Cancel</b-button>
                <!-- TODO: Setup Disable -->
                <b-button block v-show="confirmationModal.type == 'PERM'" class="mt-3" variant="warning" @click="$bvModal.hide('confirmation-window')">Disable Instead</b-button>
                <b-button block class="mt-3" variant="danger" @click="confirmationModal.callback(confirmationModal.callbackPass)">DELETE FOREVER</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');

//const _ = require('underscore');
//const {Address} = require('../libs/address.js');
import Address from '../libs/address.js'

import AddressConfirmationModal from '@/views/modals/AddressSelection.vue'

export default {
    name: 'utilities',
    components: {
        'confirm-address': AddressConfirmationModal
    },
    data(){
        return{
            tableBusy: false,
            showAddressSelect: false,
            api_path: '',
            parent_path: '',
            pkid: 'utility_id',
            name_singular: 'Utility',
            name_pluaral: 'Utilities',
            visible_fields: ['utility_name', 'utility_contact_first_name', 'utility_contact_last_name', 
                            'utility_contact_phone_number', 'utility_contact_email'],
            allPossibleFields: [],
            tableFields: [],
            values: [],
            hasNoValues: false,
            editModal: {
                type: '',
                fields: [],
                actee: {},
                parentChoices: [],
                siteTypes: [],
                processing: false,
                adrsInput: '',
                adrsFilled: false,
                showAdvancedAdrs: false,
                parsedAddress: {
                    utility_address_number: '',
                    utility_address_street_name: '',
                    utility_address_street_suffix: '',
                    utility_address_postdirectional: '',
                    utility_address_city: '',
                    utility_address_state: '',
                    utility_address_zip_code: '',
                    utility_address_zip_code_plus_four: '',
                    utility_address_zip_code_plus_two: ''
                }
            },
            hasAddress: false,
            addressName: null,
            confirmationModal: {
                title: '',
                type: '',
                callbackPass: {},
                callback: ()=>{}
            }
        }
    },
    methods: {
        toggleAdvancedAddress(){
            this.editModal.showAdvancedAdrs = !this.editModal.showAdvancedAdrs;
        },
        clearConfirmationModal(){
            this.confirmationModal = {
            title: '',
            type: '',
            callbackPass: {},
            callback: ()=>{console.log('nope')}
            }
        },
        showConfirmationModal(confirmedCallback, callbackPass, title){
            console.log(callbackPass);
            this.clearConfirmationModal();
            this.confirmationModal.title = title;
            this.confirmationModal.type = 'PERM';
            this.confirmationModal.callbackPass = callbackPass;
            this.confirmationModal.callback = confirmedCallback;
            this.$bvModal.show('confirmation-window');
        },
        confirmDeleteModal(passed){
            //console.log('Modal Deletion CONFIRMED');
            //console.log(passed);
            // Create New Choice Item
            instance.delete(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '/' + passed[this.pkid] + '.json')
            .then(async (response) => {
                this.fetchPageData();
                this.clearConfirmationModal();
                this.$bvModal.hide('confirmation-window');
            })
            .catch((error) => {
                console.log(error);
            });

        },
        removeArrayItem(main_key, index){
            console.log('Requested Removal Of:');
            console.log(this.editModal.actee[main_key][index])
            this.editModal.actee[main_key].splice(index, 1);
        },
        addArrayItem(main_key, input_key){
            //console.log(this.editModal[input_key]);
            var x = cloneDeep(this.editModal[input_key]);
            if(!Array.isArray(this.editModal.actee[main_key])){
                this.editModal.actee[main_key] = [];
            }
            this.editModal.actee[main_key].push(x);
            this.editModal[input_key] = '';

        },
        showSecondModal(){
            this.showAddressSelect = true;
        },
        hideSecondModal(info){
            this.showAddressSelect = false;
        },
        createNewHoverStart(evt){
            evt.target.classList.add('add-link-hover')
        },
        createNewHoverEnd(evt){
            evt.target.classList.remove('add-link-hover')
        },
        fetchPageData () {
            this.tableBusy = true;
            //console.log(this)
            //Get User Token and Fetch The Values Required For This Page
            instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '.json')
                .then(async (response) => {
                    //console.log(response);
                    // Set All Possible Field Values
                    this.allPossibleFields = response.data.result.fields;
                    // If There are any records to work with
                    if(response.data.result.records.length > 0){
                        // Set Them As The Values
                        // Address Management
                        response.data.result.records.forEach( (element) => {
                            // Create An Address Object For Each
                            // Write formatted address
                            var adrs = new Address(element);
                            // console.log(adrs.formatted());
                            this.addressName = adrs.name;
                            element.adrs = adrs;
                            element[adrs.name] = adrs.formatted();
                            //console.log(element);
                        });
                        //console.log(this.visible_fields);
                        this.values = response.data.result.records;
                    }else{
                        // Try To See We Can Find Any Address Field
                        var tmpExampleItem = {};
                        response.data.result.fields.forEach((fld) =>{
                            tmpExampleItem[fld.key] = null;
                        })
                        var adrs = new Address(tmpExampleItem);
                        this.addressName = adrs.name;
                        // Set Values empty and flag no values
                        this.hasNoValues = true;
                        this.values =  [];
                    }
                    // Get Fields to display 
                    var parsedDisplayFields = this.allPossibleFields.filter((f)=>{ return this.visible_fields.includes(f.key); });
                    // Set Table Fields
                    this.tableFields = parsedDisplayFields;
                    if(this.addressName != null){
                        this.hasAddress = true;
                        this.tableFields.push({
                            key: this.addressName,
                            sortable: true,
                            type: 'address_calculated'
                        });
                    }
                    // Push The Controls Field Into The Table
                    this.tableFields.push({key: "controls", sortable: false, type: "NULL"});
                    console.log(this.tableFields);
                    this.resetEditModal();
                    this.tableBusy = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        resetEditModal(){
            this.editModal.processing = false;
            this.editModal.fields = this.allPossibleFields;
            this.editModal.type = '';
            this.editModal.actee = {};
            this.editModal.adrsInput = '';
        },
        createNewRow(){
            console.log("Creating New " + this.name_singular)
            this.resetEditModal();
            this.editModal.type = "Create New";
            var adrs = new Address({utility_address_number: null});
            this.editModal.actee.adrs = adrs;
            console.log(this.editModal);
            this.$bvModal.show('choice-editor');
            //this.openCreatorWindow("Create New");
        },
        openCreatorWindow(value){
            this.editModal.type = value;
            this.$bvModal.show('choice-editor');
        },
        editRow(item){
            this.resetEditModal();
            var editThis = cloneDeep(item);
            if(this.hasAddress){
                var adrsFields = editThis.adrs.fields;
                var fieldsWOAddress = this.editModal.fields.filter((fld)=>{
                    if(adrsFields.includes(fld.key)){
                        return false;
                    }
                    return true;
                })
                // console.log("Fields Without Address Group");
                // console.log(fieldsWithAddress);
                this.editModal.fields = fieldsWOAddress;
                this.editModal.adrsInput = editThis.adrs.toString();
                //console.log(editThis.adrs.fields);
            }
            this.editModal.actee = editThis;
            this.openCreatorWindow("Edit");
            this.runAdrsInput();
        },
        editModalSave(){
            console.log(this.editModal);
            for(var k in this.editModal.parsedAddress) {
                if(this.editModal.parsedAddress[k] != null && this.editModal.parsedAddress[k] != ''){
                    this.editModal.actee[k]=this.editModal.parsedAddress[k];
                }
            }
            this.commitChangeOrCreate( this.editModal.actee );
        },
        runAdrsInput(){
            //console.log();
            var x = this.editModal.actee.adrs.parseAndUpdate(this.editModal.adrsInput);
            this.editModal.parsedAddress = x;
            console.log(x);
        },
        commitChangeOrCreate(input){
            if(this.editModal.actee[this.pkid] == '' || this.editModal.actee[this.pkid] == null){
            // Create New Choice Item
            instance.post(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '.json', input)
                .then(async (response) => {
                    this.$bvModal.hide('choice-editor');
                    this.fetchPageData();
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response)
                });
            }else{
            instance.post(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '/' + this.editModal.actee[this.pkid] + '.json', input)
                .then(async (response) => {
                    this.$bvModal.hide('choice-editor');
                    this.fetchPageData();
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response)
                });
            }
        },
        transformToProperCase(val){
            var tmp = val.replace(/_/g, " ");
            return this.capitalizeEachWord(tmp);
        },
        capitalizeEachWord(str) {
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        validateAddress(actee){
            console.log(actee.adrs);
            console.log(actee.adrs.obj);
            instance.post(process.env.VUE_APP_API_BASE_URL + '/atils/validate.json', actee.adrs.obj)
                .then(async (response) => {
                    console.log(response.data.result);
                    if(response.data.result.length > 0){
                        // Pop Open Modal Dialog For Address Selection/Confirmation
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response)
                });
            
        },
        fetchGeocode(input){
            instance.post(process.env.VUE_APP_API_BASE_URL + '/atils/geocode.json', { address: input})
                .then(async (response) => {
                    console.log(response.data.result);
                    if(response.data.result.length > 0){
                        // Pop Open Modal Dialog For Address Selection/Confirmation
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response)
                });
        }
        
    },
    beforeMount (){
        this.api_path = 'utilities';
        // Get Page Data And Reset Modal
        this.fetchPageData();
        //this.fetchSecondaryChoices();
        //this.resetEditModal();
    },
}
</script>

<style scoped>
.array-spaced{
  margin-bottom: 0.5rem;
}
.fgroup-spacer{
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
}
</style>