<template>
    <b-modal id="modal-scoped" v-model="modalVisible" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <template slot="modal-header">
            <!-- Emulate built in modal header close button action -->
            <b-button size="sm" variant="outline-danger" @click="onCloseOrHide('close')">
            Close Modal
            </b-button>
            <h5>Modal Header</h5>
        </template>

        <template slot="default" >
            <p>Modal Body with button</p>
            <b-button @click="onCloseOrHide('hide')">Hide Modal</b-button>
        </template>

        <template slot="modal-footer">
            <b>Custom Footer</b>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" variant="success" @click="onCloseOrHide('ok')">
            OK
            </b-button>
            <b-button size="sm" variant="danger" @click="onCloseOrHide('cancel')">
            Cancel
            </b-button>
            <!-- Button with custom close trigger value -->
            <b-button size="sm" variant="outline-secondary" @click="onCloseOrHide('forget')">
            Forget it
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: 'address_selection_modal',
    props: {
       modalVisible: Boolean,
       onCloseOrHide: Function
    }
}

</script>

<style>

</style>


