/*
*   Purpose: This module attempts to parse a linear address into its components, so that the components can be stored in the database.
*   Note: Retained for legacy code compatiblity, this isn't really used anymore
*/ 
const address_parser = require('parse-address'); 
export default class Address{
    constructor(val){
        let tmp = Object.keys(val);
        let typeSelector = /(^.*_address)/g;
        let componentsRegex = /(^.*_address_.*)/gm;
        // Get Only The Address Parts
        this.components = tmp.filter((t) => { 
            let x = t.match(componentsRegex);
            if(x != null){
                return true;
            }else{
                return false;
            }
        });
        console.log(this.components);

        this.hasAddress = false;
        this.adrsName = null;
        this.adrs = {
            street:{
                number: null,
                name: null,
                suffix: null,
            },
            post: null,
            city: null,
            state: null,
            zip:{
                base: null,
                four: null,
                two: null
            }  
        };
        // if there are even any components here
        if(this.components.length > 0){
            // We Have Address Components
            this.hasAddress = true;
            // Get The Base For Address, Which Will Be The Type of Address
            this.adrsName = this.components[0].match(typeSelector)[0];
            // Get The Address Components Where They Exist
            this.components.forEach((k) => {
                // Street Number
                if(k.includes("_number")){
                    this.adrs.street.number = val[k];
                }else if( k.includes("_street_name") ){
                    this.adrs.street.name = val[k];
                }else if( k.includes("_street_suffix") ){
                    this.adrs.street.suffix = val[k];
                }else if( k.includes("_postdirectional") ){
                    this.adrs.post = val[k];
                }else if( k.includes("_state") ){
                    this.adrs.state = val[k];
                }else if( k.includes("_city") ){
                    this.adrs.city = val[k];
                }else if( k.includes("_zip_code_plus_two") ){
                    this.adrs.zip.two = val[k];
                }else if( k.includes("_zip_code_plus_four") ){
                    this.adrs.zip.four = val[k];
                }else if( k.includes("_zip_code") ){
                    this.adrs.zip.base = val[k];
                }else{
                    console.log("Address Initialization Found Addt'l Field");
                    console.log(k + " : " + val[k]);
                }
            })
        }
        

    }

    get isvalid(){
        return this.hasAddress;
    }

    get fields(){
        return this.components;
    }

    get name(){
        return this.adrsName;
    }

    get hasPost(){
        if(this.adrs.post != null){
            return true;
        }else{
            return false;
        }
    }

    toString(){
        // Return entire address in single line
        return this.formatContent(this.lineOne, null, ',') + 
        this.formatContent(this.lineTwo, ' ', null) + 
        this.formatContent(this.lineLocale, ' ', null)
    }

    formatted(){
        var res = this.formatContent(this.lineOne, null, null) + 
            this.formatContent(this.lineTwo, '\n', null) + 
            this.formatContent(this.lineLocale, '\n', null);
        if(res != undefined){
            return res;
        }else{
            return null;
        }
    }

    get obj(){
        return this.adrs;
    }

    get lineOne(){
        // Return Line 1
        // Will Contain Best Effort:
        // street.number street.name street.suffix
        return this.formatContent(this.adrs.street.number, null, ' ') + 
            this.formatContent(this.adrs.street.name, null, ' ') +
            this.formatContent(this.adrs.street.suffix, null, null);
    }

    get lineTwo(){
        // Return Line Two
        // Will Contain Best Effort:
        // postfix
        return this.formatContent(this.adrs.post, null, null);
    }

    get lineLocale(){
        // Return City State Zip
        // Will Contain Best Effort:
        // city state, zip.base-zip.plus4-zip.plus2
        return this.formatContent(this.adrs.city, null, ', ') + 
            this.formatContent(this.adrs.state, null, ', ') + 
            this.formatContent(this.adrs.zip.base, null, null) + 
            this.formatContent(this.adrs.zip.four, '-', null) + 
            this.formatContent(this.adrs.zip.two, '-', null);
    }
    
    formatContent(field, pre, post){
        if(field == null || field == ''){
            return '';
        }else{
            var res = '';
            if(pre != null){ res = res + pre; }
            res = res + field;
            if(post != null){ res = res + post; }
            return res;
        }
    }

    discardBlanks(field, pre, post){
        if(field == null || field == '' ){
            return null;
        }else{
            var res = '';
            if(pre != null){ res = res + pre; }
            res = res + field;
            if(post != null){ res = res + post; }
            return res;
        }
    }

    parseAndUpdate(strInput){
        //TODO Pickup From Here
        var parsed = address_parser.parseLocation(strInput);
        console.log(parsed);
        this.adrs.street.number = ((parsed.number != undefined) ? parsed.number : null);
        var nameA = ((parsed.prefix != undefined) ? parsed.prefix : null);
        var nameB = ((parsed.street != undefined) ? parsed.street : null);
        if(nameA != null){
            if(nameB != null) { this.adrs.street.name = nameA + ". " + nameB; }else{ this.adrs.street.name = nameA; }
        }else{
            if(nameB != null){ this.adrs.street.name = nameB; }
        }
        //this.adrs.street.name = ((parsed.street != undefined) ? ((parsed.prefix != undefined && parsed.prefix != null) ? parsed.prefix + " " + parsed.street : parsed.street) : null);
        this.adrs.street.suffix = ((parsed.type != undefined) ? parsed.type : null);
        var postA = ((parsed.sec_unit_type != undefined) ? parsed.sec_unit_type : null);
        var postB = ((parsed.sec_unit_num != undefined) ? parsed.sec_unit_num : null);
        if(postA == null && postB == null){
            this.adrs.post = null;
        }else if(postA != null){
            if(postB != null) { this.adrs.post = postA + " " + postB; }else{ this.adrs.post = postA; }
        }else{
            if(postB != null){ this.adrs.post = postB; }
        }
        this.adrs.city = ((parsed.city != undefined) ? parsed.city : null);
        this.adrs.state = ((parsed.state != undefined) ? parsed.state : null);
        this.adrs.zip.base = ((parsed.zip != undefined) ? parsed.zip : null);
        this.adrs.zip.four = ((parsed.plus4 != undefined) ? parsed.plus4 : null);
        var adrsMap = {};
        adrsMap[this.adrsName + "_number"] = this.adrs.street.number;
        adrsMap[this.adrsName + "_street_name"] = this.adrs.street.name;
        adrsMap[this.adrsName + "_street_suffix"] = this.adrs.street.suffix;
        adrsMap[this.adrsName + "_postdirectional"] = this.adrs.post;
        adrsMap[this.adrsName + "_state"] = this.adrs.state;
        adrsMap[this.adrsName + "_city"] = this.adrs.city;
        //adrsMap[this.adrsName + "_zip_code_plus_two"]
        adrsMap[this.adrsName + "_zip_code_plus_four"] = this.adrs.zip.four;
        adrsMap[this.adrsName + "_zip_code"] = this.adrs.zip.base;
        return adrsMap;
    }
}
